<template>
  <Page color="info" :title="title">
    <template slot="help">
      <h3>Bedienung</h3>
      <ul>
        <li>
          Gib eine oder mehrere Klassen (oder Kürzel oder Zimmernummern) ein.
        </li>
        <li>Bestätige jedes Kürzel mit der Eingabetaste.</li>
        <li>
          Hier wird der Semesterstundenplan
          <b>ohne Stundenplnänderungen</b> angezeigt.
        </li>
      </ul>
      <h3>Neuerungen</h3>
      <ul>
        <li>Neu können mehrere Stundenpläne gleichzeitig angezeigt werden.</li>
        <li>
          Neu werden auch bei Sportlektionen die richtigen Anfangs- und
          Endzeiten angezeigt.
        </li>
      </ul>
    </template>
    <v-container class="mt-n2">
      <v-row class="align-center justify-center">
        <v-combobox
          v-model="codes"
          clearable
          multiple
          class="mr-4"
          :label="
            $_profilePerson && $_profilePerson.schoolClass
              ? 'Klassen'
              : 'Klassen, Kürzel oder Zimmer, mit Eingabetaste (Return) trennen'
          "
          singleLine
        >
          <template v-slot:selection="{ index, item }">
            <v-chip
              :color="color(index)"
              :dark="dark(index)"
              close
              @click:close="codes.splice(index, 1)"
              >{{ item }}</v-chip
            >
          </template>
        </v-combobox>
        <v-btn text outlined @click="showMine">Meiner</v-btn>

        <v-spacer v-if="nextTermVisible"></v-spacer>
        <v-btn-toggle
          v-if="nextTermVisible"
          dense
          v-model="selectedTermIndex"
          class="mr-4"
          ><v-btn text outlined>dieses Semester</v-btn>
          <v-btn text outlined>nächstes Semester</v-btn>
        </v-btn-toggle>

        <ReportButton
          v-if="version && codes.length == 1"
          text
          color="success"
          :parameters="reportParams"
          resource="report/timetable"
          >Excel</ReportButton
        >
      </v-row>
    </v-container>

    <v-card :loading="loading">
      <v-system-bar>{{ termText }}</v-system-bar>
      <Timetable :events="events"> </Timetable>
      <v-card-text class="text-right">{{ versionText }}</v-card-text>
    </v-card>
  </Page>
</template>
<script>
import { defineComponent } from "vue";
import { formatDate, formatTime } from "common/utils/date";
import { schoolClassCode } from "common/utils/school";
import ReportButton from "@/components/ReportButton";
import Timetable from "@/components/Timetable";
function dateFor(weekday, time) {
  const day = 20 + weekday;
  return new Date(`2022-03-${day}T${time}+01:00`);
}

function rooms(lesson) {
  return lesson.rooms.map((room) => room.code).join(", ");
}

function teachers(lesson) {
  return lesson.teachers.map((teacher) => teacher.code).join(", ");
}

function title(type, lesson) {
  let result = lesson.subject.code + " ";
  switch (type) {
    case "room":
      result += schoolClassCode(lesson.schoolClasses);
      result += " ";
      result += teachers(lesson);
      break;
    case "teacher":
      result += schoolClassCode(lesson.schoolClasses);
      result += " ";
      result += rooms(lesson);
      break;
    case "schoolClass":
      result += rooms(lesson);
      result += " ";
      result += teachers(lesson);
      break;
  }
  return result;
}

export default defineComponent({
  components: {
    ReportButton,
    Timetable,
  },
  props: { code: [] },
  data() {
    return {
      abortController: null,
      count: 0,
      codes: [],
      colors: [
        "blue",
        "green",
        "amber",
        "pink",

        "light-blue",
        "light-green",
        "orange",
        "purple",

        "cyan",
        "lime",
        "deep-orange",
        "indigo",

        "teal",
        "yellow",
        "red",
        "deep-purple",
      ],
      darks: [
        true,
        true,
        false,
        true,

        false,
        false,
        false,
        true,

        false,
        false,
        false,
        true,

        true,
        false,
        true,
        true,
      ],
      events: [],
      id: 0,
      loading: false,
      nextTermVisible: false,
      selectedTermIndex: 0,
      startDate: new Date(2022, 3, 21),
      type: {},
      version: null,
    };
  },
  computed: {
    reportParams() {
      return {
        type: this.type,
        id: this.id,
        next: this.selectedTermIndex == 1,
      };
    },
    title() {
      if (this.codes.length > 1) {
        return "Stundenpläne " + this.codes.join(", ");
      }
      return "Stundenplan " + this.codes.join(", ");
    },
    nextTerm() {
      return this.nextTermVisible && this.selectedTermIndex === 1;
    },
    termText() {
      if (!this.version) return "";
      return this.version.term.fullText;
    },
    versionText() {
      if (!this.version) return "";
      return `Stundenplan Version ${
        this.version.number
      }, veröffentlicht am ${formatDate(this.version.date)} um ${formatTime(
        this.version.time
      )}.`;
    },
  },
  watch: {
    code() {
      this.codes = [this.code];
    },
    codes() {
      localStorage.setItem("calendarCode", JSON.stringify(this.codes));
      this.fetchData();
    },
    selectedTermIndex() {
      localStorage.setItem("nextTerm", this.nextTerm);
      this.fetchData();
    },
  },
  methods: {
    colorhsl(index) {
      if (this.codes.length == 0) {
        return "";
      }
      const steps = 360 / this.codes.length;
      const deg = index * steps;
      return `hsl(${deg + 111}deg 70% 35%)`;
    },
    color(index) {
      return this.colors[index % this.colors.length];
    },
    dark(index) {
      return this.darks[index % this.darks.length];
    },
    async fetchData() {
      this.loading = true;

      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      const newEvents = [];
      let i = 0;
      for (const item of this.codes) {
        const timetable = await this.apiList({
          resource: "course/timetable",
          query: `code=${item}&next=${this.nextTerm}`,
          signal: this.abortController.signal,
        });

        this.id = timetable.id;
        this.version = timetable.version;
        this.type = timetable.type;
        this.nextTermVisible = timetable.nextTermVisible;
        if (
          this.nextTermVisible &&
          localStorage.getItem("nextTerm") === "true"
        ) {
          this.selectedTermIndex = 1;
        }

        if (timetable.code) {
          timetable.lessons.forEach((lesson) => {
            newEvents.push({
              name: title(timetable.type, lesson),
              start: dateFor(lesson.day.id, lesson.startTime),
              end: dateFor(lesson.day.id, lesson.endTime),
              color: this.color(i),
              dark: this.dark(i),
              timed: true,
              lesson: lesson,
            });
          });
        }
        i++;
      }

      this.events = newEvents;

      this.$router
        .push({
          query: { code: this.codes, nextTerm: this.nextTerm },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      this.loading = false;
    },
    showMine() {
      if (this.$_profilePerson.schoolClass) {
        if (!this.codes.includes(this.$_profilePerson.schoolClass.code)) {
          this.codes.push(this.$_profilePerson.schoolClass.code);
        }
      }
      if (this.$_profilePerson.code) {
        if (!this.codes.includes(this.$_profilePerson.code)) {
          this.codes.push(this.$_profilePerson.code);
        }
      }
    },
  },
  created() {
    if (this.code) {
      this.codes = [this.code];
    } else {
      if (this.$route.query.code) {
        const queryCodes = this.$route.query.code;
        if (Array.isArray(queryCodes)) {
          this.codes = queryCodes;
        } else {
          this.codes = [queryCodes];
        }
      } else {
        this.codes = localStorage.getItem("calendarCode")
          ? JSON.parse(localStorage.getItem("calendarCode"))
          : [];
      }
    }
  },
});
</script>
